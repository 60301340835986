
const React = require("react")
const toast = require('react-hot-toast')
const config = require('./src/service/config')
const captcha = require('react-google-recaptcha-v3')

const { Toaster } = toast
const { GoogleReCaptchaProvider } = captcha
const { GATSBY_GOOGLE_RECAPTCHA_KEY } = config

exports.wrapPageElement = ({ element, props }) => {
    return (
        <React.Fragment {...props}>
            <GoogleReCaptchaProvider reCaptchaKey={GATSBY_GOOGLE_RECAPTCHA_KEY}>
                {element}
                <Toaster
                    position="top-right"
                    toastOptions={{
                        duration: 3000
                    }}
                    success={{
                        duration: 2000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        }                    
                    }}
                />
            </GoogleReCaptchaProvider>
        </React.Fragment>
    )
  }

exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
  }) => {
    window.scrollTo(0, 0)
    document.body.className = document.body.className.replace("body_fixed","");
    return false
  }